import React, { useEffect, useRef } from "react";
import Login from "../components/Authentication/Login";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import { isLoggedIn, loginRedirectTo } from "../utils/auth";
import { navigate } from "gatsby-link";
import Seo from "../components/seo";
import { I18n } from "react-redux-i18n";
import { CookiesPopup } from "../components/Common/Popup/CookiesPopup";
import Notifier from "../components/Common/Snackbars/UniversalSnackbar/Notifier";
import AppSnackbarProvider from "../components/Common/Snackbars/UniversalSnackbar/SnackbarProvider";
import { ThemeProvider } from "@mui/material";
import { useCurrentTheme } from "../utils/hooks";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";

const IndexPage = () => {
  const currentTheme = useCurrentTheme();

  useEffect(() => {
    async function checkLoginStatus() {
      if (await isLoggedIn()) {
        await navigate(loginRedirectTo);
      }
    }

    void checkLoginStatus();
  }, []);

  const currentLanguage = useSelector(selectLanguage);
  const currLang = useRef(currentLanguage || "");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <Seo title={I18n.t("Common.seo.login")} />
      <ThemeProvider theme={currentTheme.theme}>
        <AppSnackbarProvider>
          <Notifier />
          <OnboardingLayout>
            <CookiesPopup />
            <Login />
          </OnboardingLayout>
        </AppSnackbarProvider>
      </ThemeProvider>
    </div>
  );
};

export default IndexPage;
